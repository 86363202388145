import {Component, OnInit} from "@angular/core";
import {ActivatedRoute} from "@angular/router";
import {InternalServicesPageData, InternalServicesPageListItem} from "src/app/interfaces/portal/internal-services-page";

@Component({
  selector: "app-internal-services-page",
  templateUrl: "./internal-services-page.component.html",
  styleUrls: ["./internal-services-page.component.scss"],
})
export class InternalServicesPageComponent implements OnInit {
  services!: InternalServicesPageListItem[];

  constructor(private route: ActivatedRoute) {}

  ngOnInit(): void {
    const page = this.route.snapshot.data["page"] as InternalServicesPageData;
    this.services = page.data;
  }
}

import {Injectable} from "@angular/core";
import {ActivatedRouteSnapshot, Resolve} from "@angular/router";
import {Apollo} from "apollo-angular";
import {forkJoin, map, Observable} from "rxjs";
import {PageType} from "src/app/enums/page-type";
import {StatisticsPageData} from "src/app/interfaces/portal/statistics-page";
import {GET_STATISTICS_PAGE} from "src/app/queries/portal/statistics-page.query";
import {ApiService} from "src/app/services/api.service";
import {SeoService} from "src/app/services/seo.service";

@Injectable({
  providedIn: "root",
})
export class StatisticsPageResolver implements Resolve<StatisticsPageData> {
  constructor(private apollo: Apollo, private apiService: ApiService, private seoService: SeoService) {}

  resolve(route: ActivatedRouteSnapshot): Observable<StatisticsPageData> {
    return forkJoin([this.apiService.getReport(), this.apollo.query({query: GET_STATISTICS_PAGE})]).pipe(
      map(([report, result]): StatisticsPageData => {
        const {page: meta} = result.data;
        return this.seoService.patchTitleAndMetaTags(route, {
          title: $localize`إحصائيات البوابة`,
          breadcrumbs: [
            {title: $localize`البوابة`, routerLink: ""},
            {title: $localize`إحصائيات البوابة`, routerLink: "statistics"},
          ],
          data: report,
          meta,
          type: PageType.INTERIOR,
        });
      }),
    );
  }
}

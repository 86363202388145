import {Injectable} from "@angular/core";
import {IconDefinition} from "@fortawesome/fontawesome-svg-core";
import {faYoutube} from "@fortawesome/free-brands-svg-icons";
import {faAddressBook} from "@fortawesome/free-regular-svg-icons";
import {
  faArrowsTurnToDots,
  faArrowUp91,
  faBook,
  faBookmark,
  faBookOpen,
  faBookOpenReader,
  faBuilding,
  faCalendarDays,
  faCertificate,
  faChalkboardUser,
  faChild,
  faChildren,
  faFlag,
  faGlobe,
  faHandshake,
  faMapLocationDot,
  faNewspaper,
  faPaperPlane,
  faPenClip,
  faPersonRays,
  faPhoneFlip,
  faSchool,
  faSchoolFlag,
  faSitemap,
  faSliders,
  faSquareShareNodes,
  faUsers,
  faUserTie,
} from "@fortawesome/free-solid-svg-icons";
import {map, Observable} from "rxjs";

import {Locale} from "../enums/locale";
import {LocalizationService} from "./localization.service";
import {PortalContentService} from "./portal-content.service";

export enum MenuItemType {
  LINK,
  GROUP,
  FRAGMENT,
}

export enum MenuArrowPosition {
  START,
  END,
}

export interface MenuItem {
  title: string;
  type: MenuItemType;
  href?: boolean;
  link?: string;
  children?: MenuItem[];
  arrowPosition?: MenuArrowPosition;
  icon?: IconDefinition;
  isVertical?: boolean;
  state?: unknown;
  category?: string;
  custom?: boolean;
}

// TODO this service should be repurposed to serve the app globally and not just the navbar (NavbarService => AppService)
@Injectable({
  providedIn: "root",
})
export class NavbarService {
  constructor(private cmsService: PortalContentService, private localizationService: LocalizationService) {}

  // TODO refactor
  // NOTE there is no need to add caching here. GQL already does the job.
  getMenu(): Observable<MenuItem[]> {
    const menu: MenuItem[] = [
      {title: $localize`الرئيسة`, type: MenuItemType.LINK, href: false, link: "/"},
      {
        title: $localize`من نحن`,
        type: MenuItemType.GROUP,
        children: [
          {
            title: $localize`من نحن`,
            type: MenuItemType.LINK,
            href: false,
            link: "/about",
            icon: faPersonRays,
          },
          {
            title: $localize`المدير العام`,
            type: MenuItemType.LINK,
            href: false,
            link: "/manager",
            icon: faUserTie,
          },
          {
            title: $localize`إدارات المكتب`,
            type: MenuItemType.LINK,
            href: false,
            link: "/departments",
            icon: faBuilding,
          },
          {
            title: $localize`الخطط الإستراتيجية`,
            type: MenuItemType.LINK,
            href: false,
            link: "/strategic-plans",
            icon: faSliders,
          },
          {
            title: $localize`وزارات التربية والتعليم في الدول الأعضاء`,
            type: MenuItemType.LINK,
            href: false,
            link: "/members",
            icon: faFlag,
          },
          {
            title: $localize`المراكز المتخصصة للمكتب`,
            type: MenuItemType.LINK,
            href: false,
            link: "/centers",
            icon: faMapLocationDot,
          },
        ],
        arrowPosition: MenuArrowPosition.START,
      },
      {
        title: $localize`البرامج و المشاريع`,
        type: MenuItemType.LINK,
        href: false,
        link: "/projects",
      },
      {
        title: $localize`المكانة الإقليمية و الدولية`,
        type: MenuItemType.GROUP,
        href: false,
        children: [
          {
            title: $localize`اتفاقيات شراكات و تعاون إقليمية و دولية`,
            type: MenuItemType.LINK,
            href: false,
            link: "/agreements",
            icon: faArrowsTurnToDots,
          },
          {
            title: $localize`المكتب في أرقام`,
            type: MenuItemType.LINK,
            href: false,
            link: "/numbers",
            icon: faArrowUp91,
          },
          ...(this.localizationService.getLocale() === Locale.ARABIC
            ? [
                {
                  title: $localize`تقارير و دراسات إقليمية و دولية`,
                  type: MenuItemType.LINK,
                  href: false,
                  link: "/reports",
                  icon: faBook,
                },
                {
                  title: $localize`التعليم 2030`,
                  type: MenuItemType.LINK,
                  href: true,
                  icon: faSchoolFlag,
                  link: "https://sdg4.abegs.org",
                },
              ]
            : []),
        ],
      },
      ...(this.localizationService.getLocale() === Locale.ARABIC
        ? [
            {
              title: $localize`الفعاليات`,
              type: MenuItemType.GROUP,
              href: false,
              children: [
                {
                  title: $localize`رزنامة الفعاليات`,
                  type: MenuItemType.LINK,
                  href: false,
                  link: "/events",
                  icon: faBookOpen,
                },
                {
                  title: $localize`المؤتمرات`,
                  type: MenuItemType.LINK,
                  href: false,
                  link: "/conferences",
                  icon: faSchool,
                },
                {
                  title: $localize`الدورات و ورش العمل`,
                  type: MenuItemType.LINK,
                  href: false,
                  link: "/courses-workshops",
                  icon: faChalkboardUser,
                },
                {
                  title: $localize`اللقاءات و الندوات`,
                  type: MenuItemType.LINK,
                  href: false,
                  link: "/appointments-seminars",
                  icon: faHandshake,
                },
                {
                  title: $localize`الاجتماعات`,
                  type: MenuItemType.LINK,
                  href: false,
                  link: "/meetings",
                  icon: faUsers,
                },
                {
                  title: $localize`الأيام الإقليمية و الدولية`,
                  type: MenuItemType.LINK,
                  href: false,
                  link: "/days",
                  icon: faCalendarDays,
                },
              ],
            },
            {
              title: $localize`الإصدارات و النشر`,
              type: MenuItemType.GROUP,
              href: false,
              children: [
                {
                  title: $localize`إصدارات المكتب`,
                  type: MenuItemType.LINK,
                  href: false,
                  link: "/publications",
                  icon: faBook,
                },
                {
                  title: "المكتبة الرقمية",
                  type: MenuItemType.LINK,
                  href: true,
                  link: "https://gdel.abegs.org/",
                  icon: faBookmark,
                },
                {
                  title: $localize`رسالة الخليج العربي`,
                  type: MenuItemType.LINK,
                  href: true,
                  link: "https://journal.abegs.org",
                  icon: faNewspaper,
                },
                {
                  title: $localize`نشرة التربية`,
                  type: MenuItemType.LINK,
                  href: true,
                  link: "https://tarbiyah-mag.abegs.org/",
                  icon: faPaperPlane,
                },
                {
                  title: $localize`برنامج تأليف الكتب`,
                  type: MenuItemType.LINK,
                  href: false,
                  link: "/publishing",
                  icon: faPenClip,
                },
                {
                  title: $localize`برنامج التعريب`,
                  type: MenuItemType.LINK,
                  href: false,
                  link: "/books",
                  icon: faBookOpen,
                },
                {
                  title: $localize`المصادر المفتوحة`,
                  type: MenuItemType.LINK,
                  href: false,
                  link: "/resources",
                  icon: faBookOpenReader,
                },
                ...(this.localizationService.getLocale() === Locale.ARABIC
                  ? [
                      {
                        title: $localize`الإنفوجرافيك التعليمي`,
                        type: MenuItemType.LINK,
                        href: false,
                        link: "/infographic-gallery",
                        icon: faSitemap,
                      },
                    ]
                  : []),
                {
                  title: $localize`مسابقة المحتوى الإثرائي للطفل`,
                  type: MenuItemType.LINK,
                  href: true,
                  link: "https://kstories.abegs.org/",
                  icon: faChildren,
                },
              ],
            },
          ]
        : []),
      {
        title: $localize`الخدمات الإلكترونية`,
        type: MenuItemType.GROUP,
        href: false,
        children: [],
        custom: true,
      },
      {
        title: $localize`المركز الإعلامي`,
        type: MenuItemType.GROUP,
        href: false,
        isVertical: true,
        children: [
          {
            title: $localize`قناة التربية`,
            type: MenuItemType.LINK,
            href: true,
            link: "https://www.youtube.com/@AbegsOrg",
            icon: faYoutube,
            isVertical: true,
          },
          // {
          //   title: $localize`وسائل التواصل الإجتماعي`,
          //   type: MenuItemType.FRAGMENT,
          //   href: false,
          //   link: "social-media",
          //   icon: faSquareShareNodes,
          //   isVertical: true,
          // },
          {
            title: $localize`الأخبار`,
            type: MenuItemType.LINK,
            href: false,
            link: "/news",
            icon: faNewspaper,
            isVertical: true,
          },
          // {
          //   title: $localize`تواصل معنا`,
          //   type: MenuItemType.LINK,
          //   href: false,
          //   link: "/contact-us",
          //   icon: faPhoneFlip,
          //   isVertical: true,
          // },
        ],
      },
    ];

    return this.cmsService.getNavbarItems().pipe(
      map((items) => {
        const {services} = items;
        const index = this.localizationService.getLocale() === Locale.ARABIC ? 6 : 4;
        menu[index].children?.push(
          ...services.map((service) => ({
            title: service.title,
            type: MenuItemType.LINK,
            href: true,
            link: service.link,
            category: service.category,
          })),
        );

        return menu;
      }),
    );
  }
}

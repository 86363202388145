<app-page>
  <page-header></page-header>
  <page-content>
    <div class="container">
      <div class="row justify-content-center">
        <div *ngFor="let service of services; index as i" class="col-lg-4 col-sm-6 mt-4">
          <div class="card h-100 rounded-0 hover-border-primary">
            <img [src]="service.image | imageLink" class="card-img-top service-image" [alt]="service.image.title" />
            <div class="card-body d-flex align-items-center justify-content-center">
              <p class="card-text">
                {{ service.title }}
              </p>
            </div>
            <a *ngIf="service.link" [href]="service.link" class="stretched-link"></a>
          </div>
        </div>
      </div>
    </div>
  </page-content>
  <page-footer></page-footer>
</app-page>

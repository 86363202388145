import {Component, ElementRef, HostListener, OnDestroy, OnInit, ViewChild} from "@angular/core";
import {faFacebook, faInstagram, faXTwitter, faYoutube} from "@fortawesome/free-brands-svg-icons";
import {faChevronUp, faEnvelope, faLocationDot, faPhone, faQuestion} from "@fortawesome/free-solid-svg-icons";
import {ReplaySubject, takeUntil} from "rxjs";
import {Locale} from "src/app/enums/locale";
import {ContactUsPage} from "src/app/interfaces/portal/contact-us-page";
import {LocalizationService} from "src/app/services/localization.service";
import {PortalContentService} from "src/app/services/portal-content.service";
import {WindowService} from "src/app/services/window.service";

@Component({
  selector: "app-footer-links",
  templateUrl: "./footer-links.component.html",
  styleUrls: ["./footer-links.component.scss"],
})
export class FooterLinksComponent implements OnInit, OnDestroy {
  faLocationDot = faLocationDot;
  faPhone = faPhone;
  faEnvelope = faEnvelope;
  faQuestion = faQuestion;
  faYoutube = faYoutube;
  faInstagram = faInstagram;
  faFacebook = faFacebook;
  faChevronUp = faChevronUp;
  faXTwitter = faXTwitter;

  SCROLL_OFFSET = 300;

  contact!: ContactUsPage;

  @ViewChild("scrollTopBtn") scrollTopButtonRef!: ElementRef<HTMLButtonElement>;

  unsubscribe$ = new ReplaySubject(1);

  footerLinks = [
    {
      title: $localize`المكتب في أرقام`,
      link: "/numbers",
      href: false,
    },
    ...(this.localizationService.getLocale() === Locale.ARABIC
      ? [
          {
            title: $localize`إنفوجرافيك`,
            link: "/infographic-gallery",
            href: false,
          },
          {
            title: $localize`الفعاليات`,
            link: "/events",
            href: false,
          },
          {
            title: $localize`الأنظمة الداخلية`,
            link: "https://services.abegs.org",
            href: true,
          },
          {
            title: $localize`استبانة تقويم البوابة`,
            link: "https://forms.gle/qZmw6kALYEGwbJZC9",
            href: true,
          },
        ]
      : []),
    {
      title: $localize`إحصائيات البوابة`,
      link: "/statistics",
      href: false,
    },
    {
      title: $localize`خريطة الموقع`,
      link: "/sitemap",
      href: false,
    },
    {
      title: $localize`سياسة الخصوصية`,
      link: "/privacy",
      href: false,
    },
  ];
  constructor(
    private cmsService: PortalContentService,
    private windowService: WindowService,
    private localizationService: LocalizationService,
  ) {}

  ngOnInit() {
    this.fetchContactUsDetails();
  }

  @HostListener("window:scroll")
  onWindowScroll() {
    if (!this.scrollTopButtonRef) return;

    if ((this.windowService.scrollY || 0) > this.SCROLL_OFFSET) {
      this.scrollTopButtonRef.nativeElement.classList.add("shown");
    } else {
      this.scrollTopButtonRef.nativeElement.classList.remove("shown");
    }
  }

  fetchContactUsDetails() {
    this.cmsService
      .getContactUsDetails()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: (result) => {
          this.contact = result;
        },
      });
  }
  ngOnDestroy(): void {
    this.unsubscribe$.next(1);
    this.unsubscribe$.complete();
  }
}

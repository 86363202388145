import {gql} from "apollo-angular";
import {InternalServicesPage, InternalServicesPageListItem} from "src/app/interfaces/portal/internal-services-page";

export const GET_INTERNAL_SERVICES_PAGE = gql<{page: InternalServicesPage; services: InternalServicesPageListItem[]}, void>`
  query {
    page: contact_us_page {
      title
      image {
        id
        filename: filename_download
        title
      }
    }
    services(filter: {_and: [{status: {_eq: "published"}}, {is_internal: {_eq: true}}]}, sort: ["date_created"]) {
      title: name
      image {
        id
        filename: filename_download
        title
      }
      link: url
    }
  }
`;

import {Component, OnInit} from "@angular/core";
import {ActivatedRoute} from "@angular/router";
import {faClock, faEye, faShare, faUsers, IconDefinition} from "@fortawesome/free-solid-svg-icons";
import {ChartConfiguration, ChartOptions, Plugin} from "chart.js";
import {Dimension} from "src/app/enums/dimension";
import {Metric} from "src/app/enums/metric";
import {AnalyticsReport, StatisticsPage, StatisticsPageData} from "src/app/interfaces/portal/statistics-page";
import {environment} from "src/environments/environment";

@Component({
  selector: "app-statistics-page",
  templateUrl: "./statistics-page.component.html",
  styleUrls: ["./statistics-page.component.scss"],
})
export class StatisticsPageComponent implements OnInit {
  Dimension = Dimension;

  meta!: StatisticsPage;
  report!: AnalyticsReport;

  gradient: Record<number, number[]> = {
    0: [239, 238, 105, 1],
    20: [152, 207, 111, 1],
    40: [83, 169, 118, 1],
    60: [38, 128, 115, 1],
    80: [32, 87, 95, 1],
    100: [33, 49, 62, 1],
  };

  pies!: {
    [key in Dimension]: {
      datasets?: ChartConfiguration<"pie">["data"]["datasets"];
      labels?: (string | string[])[];
      options?: ChartOptions<"pie">;
      plugins: Plugin<"pie">[];
      legend?: boolean;
    };
  };
  piesDimensions = [Dimension.BROWSER, Dimension.DEVICE_CATEGORY, Dimension.OPERATING_SYSTEM, Dimension.MOBILE_DEVICE_MODEL];

  tiles: {
    icon: IconDefinition;
    name: string;
    value: string;
  }[] = [];

  bar!: {
    datasets?: ChartConfiguration<"bar">["data"]["datasets"];
    labels?: (string | string[])[];
    options?: ChartOptions<"bar">;
    plugins: Plugin<"bar">[];
    legend?: boolean;
  };

  tableRowMaxCount!: number;

  constructor(private route: ActivatedRoute) {}

  ngOnInit(): void {
    const {meta, ...page} = this.route.snapshot.data["page"] as StatisticsPageData;

    this.meta = meta;
    this.report = page.data;

    this.initTiles();
    this.initPies();
    this.initBar();

    this.tableRowMaxCount = Math.max(this.report.dimensions.country.rows.length, this.report.dimensions.city.rows.length);
  }

  initTiles() {
    this.tiles.push({
      icon: faUsers,
      name: $localize`المستخدمين`,
      value: this.report.metrics.totalUsers.toString(),
    });
    this.tiles.push({
      icon: faClock,
      name: $localize`الزيارات`,
      value: this.report.metrics.sessions.toString(),
    });
    this.tiles.push({
      icon: faEye,
      name: $localize`المشاهدات`,
      value: this.report.metrics.screenPageViews.toString(),
    });
    this.tiles.push({
      icon: faShare,
      name: $localize`معدل الارتداد`,
      value: `${(this.report.metrics.bounceRate * 100).toFixed(2)}%`,
    });
  }

  initPies() {
    this.piesDimensions.forEach((dimension) => {
      this.initPie(dimension);
    });
  }

  initPie(dimension: Dimension) {
    if (!this.pies) this.pies = {} as unknown as typeof this.pies;
    const colors = this.getColors(this.report.dimensions[dimension].rows.length);
    this.pies[dimension] = {
      datasets: [
        {
          data: this.report.dimensions[dimension].rows.map(
            (row) => +row[this.report.dimensions[dimension].headers.indexOf(Metric.SCREEN_PAGEVIEWS)],
          ),
          backgroundColor: colors,
          borderColor: colors.map(() => "rgba(255, 255, 255, 1)"),
        },
      ],
      labels: this.report.dimensions[dimension].rows.map((row) =>
        row[this.report.dimensions[dimension].headers.indexOf(dimension)].toString(),
      ),
      options: {
        responsive: true,
      },
      plugins: [],
      legend: true,
    };
  }

  // Based on code from https://stackoverflow.com/questions/28828915/how-set-color-family-to-pie-chart-in-chart-js
  getColors(count: number) {
    const colors: string[] = [];

    const steps = Object.keys(this.gradient).map(Number);
    steps.sort((a, b) => a - b);

    for (let i = 0; i < count; i++) {
      const gradientIndex = (i + 1) * (100 / (count + 1));
      for (let j = 0; j < steps.length; j++) {
        const step = steps[j];
        if (gradientIndex === step) {
          colors.push(`rgba(${this.gradient[step].join(", ")})`);
          break;
        }

        if (gradientIndex < step) {
          const prevStep = steps[j - 1];
          const gradientPartIndex = (gradientIndex - prevStep) / (step - prevStep);
          const color = [];
          for (let channel = 0; channel < 4; channel++) {
            color[channel] =
              this.gradient[prevStep][channel] -
              (this.gradient[prevStep][channel] - this.gradient[step][channel]) * gradientPartIndex;
            if (channel < 3) color[channel] = Math.round(color[channel]);
          }
          colors.push(`rgba(${this.gradient[step].join(", ")})`);
          break;
        }
      }
    }

    return colors;
  }

  initBar() {
    const colors = this.getColors(this.report.dimensions[Dimension.PAGE_TITLE].rows.length);

    const data = this.report.dimensions[Dimension.PAGE_TITLE].rows;
    data.sort(
      (a, b) =>
        +b[this.report.dimensions[Dimension.PAGE_TITLE].headers.indexOf(Metric.SCREEN_PAGEVIEWS)] -
        +a[this.report.dimensions[Dimension.PAGE_TITLE].headers.indexOf(Metric.SCREEN_PAGEVIEWS)],
    );
    this.bar = {
      datasets: [
        {
          data: data.map(
            (row) => +row[this.report.dimensions[Dimension.PAGE_TITLE].headers.indexOf(Metric.SCREEN_PAGEVIEWS)],
          ),
          backgroundColor: colors,
          borderColor: colors.map(() => "rgba(255, 255, 255, 1)"),
        },
      ],
      labels: data.map((row) =>
        row[this.report.dimensions[Dimension.PAGE_TITLE].headers.indexOf(Dimension.PAGE_TITLE)].toString(),
      ),
      options: {
        responsive: true,
        indexAxis: "y",
      },
      plugins: [],
      legend: false,
    };
  }

  getFlagPath(relativePath: string): string {
    return `${environment.serverURI}/${relativePath}`;
  }
}

<app-page>
  <page-header></page-header>

  <page-content>
    <div class="container">
      <h1 i18n class="text-secondary fw-bold text-center py-2 my-5">المؤشرات العامة</h1>

      <div class="row g-3">
        <div *ngFor="let tile of tiles" class="col-lg-3 col-sm-6">
          <div class="card hover-border-primary w-100 py-3">
            <div class="card-body">
              <h3 class="text-center">
                <fa-icon [icon]="tile.icon" class="me-2"></fa-icon>
                {{ tile.name }}
              </h3>
              <span
                style="font-size: var(--statistics-card-title-fontSize)"
                class="d-flex justify-content-center align-items-center flex-nowrap text-center text-secondary fw-bold"
              >
                {{ tile.value }}
              </span>
            </div>
          </div>
        </div>
      </div>

      <h1 i18n class="text-secondary fw-bold text-center py-2 my-5">مؤشرات التصفح</h1>

      <div class="row justify-content-center g-2 pt-2">
        <div class="col-md-6 col-lg-3 col-12 p-2">
          <div class="hover-border-primary card p-4">
            <h3 i18n class="text-center mb-4">نوع المتصفح</h3>
            <canvas
              baseChart
              [type]="'pie'"
              [datasets]="pies[Dimension.BROWSER].datasets"
              [labels]="pies[Dimension.BROWSER].labels"
              [options]="pies[Dimension.BROWSER].options"
              [plugins]="pies[Dimension.BROWSER].plugins"
              [legend]="pies[Dimension.BROWSER].legend"
            ></canvas>
          </div>
        </div>
        <div class="col-md-6 col-lg-3 col-12 p-2">
          <div class="hover-border-primary card p-4">
            <h3 i18n class="text-center mb-4">أنواع الأجهزة</h3>
            <canvas
              baseChart
              [type]="'pie'"
              [datasets]="pies[Dimension.DEVICE_CATEGORY].datasets"
              [labels]="pies[Dimension.DEVICE_CATEGORY].labels"
              [options]="pies[Dimension.DEVICE_CATEGORY].options"
              [plugins]="pies[Dimension.DEVICE_CATEGORY].plugins"
              [legend]="pies[Dimension.DEVICE_CATEGORY].legend"
            ></canvas>
          </div>
        </div>
        <div class="col-md-6 col-lg-3 col-12 p-2">
          <div class="hover-border-primary card p-4">
            <h3 i18n class="text-center mb-4">أنظمة التشغيل</h3>
            <canvas
              baseChart
              [type]="'pie'"
              [datasets]="pies[Dimension.OPERATING_SYSTEM].datasets"
              [labels]="pies[Dimension.OPERATING_SYSTEM].labels"
              [options]="pies[Dimension.OPERATING_SYSTEM].options"
              [plugins]="pies[Dimension.OPERATING_SYSTEM].plugins"
              [legend]="pies[Dimension.OPERATING_SYSTEM].legend"
            ></canvas>
          </div>
        </div>
        <div class="col-md-6 col-lg-3 col-12 p-2">
          <div class="hover-border-primary card p-4">
            <h3 i18n class="text-center mb-4">نوع الجوال</h3>
            <canvas
              baseChart
              [type]="'pie'"
              [datasets]="pies[Dimension.MOBILE_DEVICE_MODEL].datasets"
              [labels]="pies[Dimension.MOBILE_DEVICE_MODEL].labels"
              [options]="pies[Dimension.MOBILE_DEVICE_MODEL].options"
              [plugins]="pies[Dimension.MOBILE_DEVICE_MODEL].plugins"
              [legend]="pies[Dimension.MOBILE_DEVICE_MODEL].legend"
            ></canvas>
          </div>
        </div>
      </div>

      <h1 i18n class="text-secondary fw-bold text-center py-2 my-5">مشاهدات الصفحات</h1>

      <div class="row justify-content-center">
        <div class="col-12">
          <div class="hover-border-primary card p-4">
            <canvas
              baseChart
              [type]="'bar'"
              [datasets]="bar.datasets"
              [labels]="bar.labels"
              [options]="bar.options"
              [plugins]="bar.plugins"
              [legend]="bar.legend"
            ></canvas>
          </div>
        </div>
      </div>

      <h1 i18n class="text-secondary fw-bold text-center py-2 my-5">المشاهدات حول العالم</h1>

      <div class="row justify-content-center">
        <div class="col-12 col-md-6">
          <div class="hover-border-primary card p-4 d-flex justify-content-center align-items-center">
            <h3 i18n class="text-center mb-4">حسب الدول</h3>
            <table class="table table-hover">
              <thead>
                <tr>
                  <th i18n class="p-3" scope="col">الدولة</th>
                  <th i18n class="p-3" scope="col">المستخدمين</th>
                </tr>
              </thead>
              <tbody [ngStyle]="{'height.px': tableRowMaxCount * 46.61}">
                <tr *ngFor="let row of report.dimensions.country.rows">
                  <td class="p-3">
                    <img
                      crossorigin="anonymous"
                      *ngIf="!!report.assets[row[0]]"
                      [src]="getFlagPath(report.assets[row[0]])"
                      [alt]="row[0]"
                      style="width: 25px; height: 16px"
                      class="me-2"
                    />
                    {{ row[0] }}
                  </td>
                  <td class="p-3">{{ row[1] }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="col-12 col-md-6">
          <div class="hover-border-primary card p-4 d-flex justify-content-center align-items-center">
            <h3 i18n class="text-center mb-4">حسب المدن</h3>
            <table class="table table-hover">
              <thead>
                <tr>
                  <th i18n class="p-3" scope="col">المدينة</th>
                  <th i18n class="p-3" scope="col">المستخدمين</th>
                </tr>
              </thead>
              <tbody [ngStyle]="{'height.px': tableRowMaxCount * 46.61}">
                <tr *ngFor="let row of report.dimensions.city.rows">
                  <td class="p-3">{{ row[0] }}</td>
                  <td class="p-3">{{ row[1] }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </page-content>
  <page-footer></page-footer>
</app-page>

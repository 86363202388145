export enum Dimension {
  BROWSER = "browser",
  DEVICE_CATEGORY = "deviceCategory",
  OPERATING_SYSTEM = "operatingSystem",
  MOBILE_DEVICE_MODEL = "mobileDeviceModel",
  PAGE_TITLE = "pageTitle",
  COUNTRY_ID = "countryId",
  COUNTRY = "country",
  CITY = "city",
}

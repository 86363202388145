import {Component, OnInit} from "@angular/core";
import {ActivatedRoute} from "@angular/router";
import {faAngleLeft, faLink} from "@fortawesome/free-solid-svg-icons";
import {SitemapPageData} from "src/app/interfaces/portal/sitemap-page";
import {MenuItem, MenuItemType} from "src/app/services/navbar.service";

@Component({
  selector: "app-sitemap-page",
  templateUrl: "./sitemap-page.component.html",
  styleUrls: ["./sitemap-page.component.scss"],
})
export class SitemapPageComponent implements OnInit {
  MenuItemType = MenuItemType;

  faAngleLeft = faAngleLeft;
  faLink = faLink;

  menu!: MenuItem[];

  constructor(private route: ActivatedRoute) {}

  ngOnInit(): void {
    const {data: menu} = this.route.snapshot.data["page"] as SitemapPageData;

    this.menu = menu;

    this.menu.push({title: $localize`إحصائيات البوابة`, type: MenuItemType.LINK, href: false, link: "/statistics"});
    this.menu.push({title: $localize`خريطة الموقع`, type: MenuItemType.LINK, href: false, link: "/sitemap"});
    this.menu.push({title: $localize`سياسة الخصوصية`, type: MenuItemType.LINK, href: false, link: "/privacy"});
  }
}

import {HttpClient} from "@angular/common/http";
import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {environment} from "src/environments/environment";

import {AnalyticsReport} from "../interfaces/portal/statistics-page";

@Injectable({
  providedIn: "root",
})
export class ApiService {
  constructor(private httpClient: HttpClient) {}

  submitAction<T>(action: string, token: string, data: T): Observable<void> {
    return this.httpClient.post<void>(`${environment.serverURI}/recaptcha/${action}`, {token, ...(data || {})});
  }

  getReport(): Observable<AnalyticsReport> {
    return this.httpClient.get<AnalyticsReport>(`${environment.serverURI}/report`);
  }
}

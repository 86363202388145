<app-page>
  <page-header></page-header>
  <page-content>
    <div class="container">
      <div class="row">
        <div class="col-md-5 my-4 position-relative">
          <img [src]="meta.pageImage | imageLink" class="img-fluid" [alt]="meta.title" />

          <div class="yo-experience bg-white border-very-light-gray p-3">
            <div class="wrapper d-flex flex-column align-items-center justify-content-center">
              <span class="h1 d-block fw-bold text-primary text-center">{{ meta.experienceInYears }}</span>
              <span class="h5 d-block text-dark text-center">
                <ng-container i18n>عاما</ng-container>
                <br />
                <ng-container i18n>من الخبرة</ng-container>
              </span>
            </div>
          </div>
        </div>

        <div class="col-md-7 my-4">
          <div class="mt-5">
            <div
              *ngFor="let plan of plans; let index = index"
              class="btn btn-outline-success py-4 fw-bold text-start px-4 rounded-0 w-100 mt-4 border-very-light-gray"
              data-bs-toggle="modal"
              data-bs-target="#strategic-plans-modal"
              [attr.data-bs-title]="plan.name"
              [attr.data-bs-body]="plan.summary"
              [attr.data-bs-image]="getImageLink(plan.image)"
              (click)="setModalData(plan)"
            >
              {{ index + 1 }}. {{ plan.name }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      class="modal fade blur"
      id="strategic-plans-modal"
      data-bs-keyboard="false"
      tabindex="-1"
      aria-labelledby="strategic-plans-modalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-scrollable modal-dialog-centered modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h1 class="modal-title fs-5" id="strategic-plans-modalLabel">{{ modalData?.name }}</h1>
            <button type="button" class="btn-close me-0 ms-auto" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <img *ngIf="modalData" [src]="modalData.image | imageLink" class="modal-image img-fluid" style="height: 400px;" alt="" />
          <div class="modal-body p-4" id="strategic-plans-modalBody" [innerHTML]="modalData?.summary"></div>
          <div class="modal-footer">
            <button i18n type="button" class="btn btn-secondary" data-bs-dismiss="modal">إغلاق</button>
          </div>
        </div>
      </div>
    </div>
  </page-content>
  <page-footer></page-footer>
</app-page>

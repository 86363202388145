import {Injectable} from "@angular/core";
import {
  faBookOpen,
  faBuilding,
  faCalendarDays,
  faChalkboardUser,
  faGlobe,
  faHandshake,
} from "@fortawesome/free-solid-svg-icons";
import {Apollo} from "apollo-angular";
import {endOfDay, startOfDay} from "date-fns";
import {map, Observable} from "rxjs";
import {NewsLetter} from "src/app/interfaces/newsletters";
import {ContactUsPage} from "src/app/interfaces/portal/contact-us-page";
import {EventFilter, EventSlug, EventsPageListItem} from "src/app/interfaces/portal/events-page";
import {InfographFilter, InfographicGalleryPageListItem} from "src/app/interfaces/portal/infographic-gallery-page";
import {NewsFilter, NewsPageListItem, NewsSort} from "src/app/interfaces/portal/news-page";
import {ProjectFilter, ProjectSort, ProjectsPageListItem} from "src/app/interfaces/portal/projects-page";
import {Publication, PublicationFilter, PublicationSort} from "src/app/interfaces/portal/publications-page";
import {GET_CONTACT_US_PAGE} from "src/app/queries/portal/contact-us-page.query";
import {getEventsByFilterFactory} from "src/app/queries/portal/events-page.query";
import {getInfographocsByFilterFactory} from "src/app/queries/portal/infographic-gallery-page.query";
import {GET_NAVBAR_ITEMS} from "src/app/queries/portal/navbar.query";
import {getNewsByFilterFactory} from "src/app/queries/portal/news-page.query";
import {GET_SUBSCRIBED_USER_BY_EMAIL, SUBSCRIBE_TO_NEWSLETTERS} from "src/app/queries/portal/newsletters.query";
import {getProjectsByFilterFactory} from "src/app/queries/portal/projects-page.query";
import {
  GET_PUBLICATION_PAGE,
  getPublicationsByFilterFactory,
  UPDATE_PUBLICATION_VIEWS_COUNT_BY_ID,
} from "src/app/queries/portal/publications-page.query";
import {environment} from "src/environments/environment";

import {EventCategoryPageListItem} from "../interfaces/portal/event-category-page";
import {Report, ReportsFilter, ReportsSort} from "../interfaces/portal/reports-page";
import {GET_EVENTS_BY_SLUG} from "../queries/portal/event-category-page.query";
import {getReportsByFilterFactory} from "../queries/portal/reports-page.query";
import {LocalizationService} from "./localization.service";

@Injectable({
  providedIn: "root",
})
export class PortalContentService {
  constructor(private apollo: Apollo, private localizationService: LocalizationService) {}

  listEvents(page: number, filter: EventFilter = {}): Observable<EventsPageListItem[]> {
    return this.apollo
      .query({
        query: getEventsByFilterFactory(filter),
        variables: {
          page,
          limit: environment.limit.events,
          startDate: filter?.startDate ? new Date(filter.startDate) : startOfDay(new Date()),
          endDate: filter?.endDate ? new Date(filter.endDate) : endOfDay(new Date()),
          name: filter?.name,
          content: filter?.content,
        },
      })
      .pipe(
        map((result): EventsPageListItem[] => {
          return result.data.events;
        }),
      );
  }

  listEventsBySlug(startDate: Date, endDate: Date, slug: EventSlug[]): Observable<EventCategoryPageListItem[]> {
    return this.apollo
      .query({
        query: GET_EVENTS_BY_SLUG,
        variables: {
          startDate: startDate,
          endDate: endDate,
          slug,
        },
      })
      .pipe(
        map((result): EventCategoryPageListItem[] => {
          const {events} = result.data;
          return events;
        }),
      );
  }

  listNews(page: number, filter: NewsFilter = {}, sort: NewsSort = "-date"): Observable<NewsPageListItem[]> {
    return this.apollo
      .query({
        query: getNewsByFilterFactory(filter, sort)(this.localizationService.getI18nFns()),
        variables: {
          page,
          limit: environment.limit.news,
          title: filter.title ? filter.title : undefined,
          content: filter.content ? filter.content : undefined,
        },
      })
      .pipe(
        map((result): NewsPageListItem[] => {
          return result.data.news;
        }),
      );
  }

  listReports(filter: ReportsFilter = {}, sort: ReportsSort = "-date_created"): Observable<Report[]> {
    return this.apollo
      .query({
        query: getReportsByFilterFactory(filter, sort),
        variables: {
          title: filter.title ? filter.title : undefined,
        },
      })
      .pipe(
        map((result): Report[] => {
          return result.data.reports;
        }),
      );
  }

  listProjects(
    page: number,
    filter: ProjectFilter = {},
    sort: ProjectSort = "-date_created",
  ): Observable<ProjectsPageListItem[]> {
    return this.apollo
      .query({
        query: getProjectsByFilterFactory(filter, sort)(this.localizationService.getI18nFns()),
        variables: {
          page,
          limit: environment.limit.projects,
          year: filter.year ? filter.year : undefined,
          center: filter.center ? filter.center : undefined,
        },
      })
      .pipe(map((result) => result.data.projects));
  }

  getPublicationsByFilter(
    page: number,
    filter: PublicationFilter = {},
    sort: PublicationSort = "release_date",
  ): Observable<Publication[]> {
    return this.apollo
      .query({
        query: getPublicationsByFilterFactory(filter, sort),
        variables: {
          page,
          limit: environment.limit.publications,
          title: filter.title ? filter.title : undefined,
          author: filter.author ? filter.author : undefined,
          center: filter.center ? filter.center : undefined,
          summary: filter.summary ? filter.summary : undefined,
          classification: filter.classification ? filter.classification : undefined,
        },
      })
      .pipe(map((result) => result.data.publications));
  }

  updatePublicationViewsCount(publicationId: number | string, views: number) {
    return this.apollo
      .mutate({
        mutation: UPDATE_PUBLICATION_VIEWS_COUNT_BY_ID,
        variables: {
          publicationId,
          views,
        },
      })
      .pipe(
        map((result) => {
          const publication = result.data?.publication;
          return publication;
        }),
      );
  }

  subscribeToNewsLetters(email: string): Observable<void> {
    return this.apollo
      .mutate({
        mutation: SUBSCRIBE_TO_NEWSLETTERS,
        variables: {email}, // TODO validate
      })
      .pipe(
        map(() => {
          return;
        }),
      );
  }

  getSubscribedUser(email: string): Observable<NewsLetter> {
    return this.apollo
      .query({
        query: GET_SUBSCRIBED_USER_BY_EMAIL,
        variables: {email}, // TODO validate
      })
      .pipe(
        map((result): NewsLetter => {
          const {newsletters} = result.data;
          return newsletters;
        }),
      );
  }

  listInfographics(page: number, filter: InfographFilter = {}): Observable<InfographicGalleryPageListItem[]> {
    return this.apollo
      .query({
        query: getInfographocsByFilterFactory(filter),
        variables: {
          page,
          limit: environment.limit.infographicsGallery,
          title: filter?.title,
          publicationName: filter?.publicationName,
        },
      })
      .pipe(
        map((result): InfographicGalleryPageListItem[] => {
          return result.data.infographics;
        }),
      );
  }

  getContactUsDetails(): Observable<ContactUsPage> {
    return this.apollo
      .query({
        query: GET_CONTACT_US_PAGE(this.localizationService.getI18nFns()),
      })
      .pipe(
        map((result): ContactUsPage => {
          const contactus = result.data.page;
          return contactus;
        }),
      );
  }

  getNavbarItems() {
    return this.apollo
      .query({
        query: GET_NAVBAR_ITEMS(this.localizationService.getI18nFns()),
      })
      .pipe(
        map((result) => {
          const items = result.data;
          return items;
        }),
      );
  }

  getPublicationById(publicationId: string | number) {
    return this.apollo
      .query({
        query: GET_PUBLICATION_PAGE,
        variables: {
          publicationId,
        },
      })
      .pipe(
        map((result) => {
          return result.data.publications[0];
        }),
      );
  }

  mapEventSlugToCategoryPageRoute(slug: EventSlug): string {
    switch (slug) {
      case "events":
      case "conferences":
      case "meetings":
      case "days":
        return slug;
      case "courses":
      case "workshops":
        return "courses-workshops";
      case "appointments":
      case "seminars":
        return "appointments-seminars";
      default:
        throw new Error("Unknown slug");
    }
  }

  // TODO refactor to get names from cms
  mapEventSlugToCategoryPageName(slug: EventSlug): string {
    switch (slug) {
      case "events":
        return $localize`الفعاليات`;
      case "conferences":
        return $localize`المؤتمرات`;
      case "meetings":
        return $localize`الإجتماعات`;
      case "days":
        return $localize`الأيام الإقليمية و الدولية`;
      case "courses":
      case "workshops":
        return $localize`الدورات و ورش العمل`;
      case "appointments":
      case "seminars":
        return $localize`اللقاءات و الندوات`;
      default:
        throw new Error("Unknown slug");
    }
  }

  getEventSlugIcon(slug: EventSlug) {
    switch (slug) {
      case "conferences":
        return faBookOpen;
      case "courses":
      case "workshops":
        return faChalkboardUser;
      case "appointments":
      case "seminars":
        return faHandshake;
      case "meetings":
        return faBuilding;
      case "days":
        return faCalendarDays;
      default:
        return faGlobe;
    }
  }
}
